import { Button, CheckboxV2, Flex, Input, Text } from "@pancakeswap/uikit";
import { IconDeleteImage, IconUpload, IconUploadImage } from "icons";
import { WEB3_API_KEY } from "config";
import { useEffect, useMemo, useState } from "react";
import ImageUploading from "react-images-uploading";
import styled from "styled-components";
import { Web3Storage } from 'web3.storage';
import { GetDataShareHolders } from "state/shareholders";


const UploadImageMobile = () => {
    return (
        <Flex width="100%" flexDirection="column" justifyContent="space-between" style={{ gap: "12px" }}>
            <Text bold color="var(--neutral-05, #33383F);" fontSize="18px">Logo</Text>
            <ContainerUpload>
                <Flex
                    width="100%"
                    height="100%"
                    justifyContent="flex-start"
                    style={{ gap: "1rem" }}
                >
                    <IconUpload fill="#33383F" />
                    <Flex style={{ gap: "1rem" }} flexDirection="column" height="100%" justifyContent="center" alignItems="flex-start">
                        <CsButton
                            color="#fff"
                            disabled
                            startIcon={<IconUploadImage fill="#fff" />}
                        >
                            Tải hình ảnh
                        </CsButton>
                        <Text textAlign="center" color="#718096" fontSize={["10px", , , "12px"]}>Hỗ trợ file PNG, JPEG, JPG</Text>
                    </Flex>
                </Flex>
            </ContainerUpload>
        </Flex>
    )
}

export default UploadImageMobile;

const CsImg = styled.img`
    width:130px;
    height:130px;
    border-radius: 50%;
    overflow:hidden;
    @media screen and (max-width:768px) {
        width:100px;
        height:100px;
    }
    /* object-fit: fill; */
`
const ContainerUpload = styled(Flex)`
    display:flex;
    width:100%;
    justify-content:center;
    border:3px dashed rgba(53, 93, 255, 0.25);
    border-radius:12px;
    align-items:flex-start;
    flex-direction:column;
    justify-content:space-between;
    padding:1rem;
    gap: 1rem;
    stroke-width: 2px; 
    stroke: rgba(53, 93, 255, 0.25);
    @media screen and (max-width:768px) {
        gap: 0px;
        padding:1rem;
    }
`
const CsButtonDelete = styled.div`
    width: 40px;
    height:40px;
    padding:0;
    bottom: 0%;
    right: 0%;
    position:absolute;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    background-color: #F0F0F0 ;
    border-radius: 50%;
    cursor:pointer;
    @media screen and (max-width:768px) {
        width: 30px;
        height:30px;
    }
`
const CsButton = styled(Button)`
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    gap:1rem;
    border-radius: 32px;
    padding:12px 18px;
    color:#fff !important;
    background-color: #9A9FA5 !important;
    @media screen and (max-width:768px) {
        padding:10px 12px;
        font-size:12px;
    }
`
const ContainerImg = styled(Flex)`
    position:relative;
    flex-direction: column;
`