import { Button, CheckboxV2, Flex, Input, Text } from "@pancakeswap/uikit";
import { IconDeleteImage, IconUpload, IconUploadImage } from "icons";
import { WEB3_API_KEY } from "config";
import { useEffect, useMemo, useState } from "react";
import ImageUploader from "react-images-uploading";
import styled from "styled-components";
import { Web3Storage } from 'web3.storage';
import { GetDataShareHolders } from "state/shareholders";
import { Spin } from "antd/lib";

interface Props {
    handleCidLink: (newCidLink) => void
    handleErrorImg: (newError) => void
    handleDelete: (newStatus) => void
    defaultImage: any
    cidLink: string
    errorImg: boolean
    deleteImg: boolean

}
const UploadImage: React.FC<Props> = ({
    handleCidLink,
    handleErrorImg,
    handleDelete,
    defaultImage,
    cidLink,
    errorImg,
    deleteImg
}) => {
    const { stock, isLoading } = GetDataShareHolders()
    const client = new Web3Storage({ token: WEB3_API_KEY })
    const [defaultImg, setDefaultImg] = useState(defaultImage);
    const [images, setImages] = useState([]);

    const [pendingImg, setPendingImg] = useState(false);
    const renderHttpImage = useMemo(() => {
        return cidLink?.length > 0 ? ("https://" + cidLink).toString() : ("https://" + defaultImg).toString();
    }, [defaultImage, cidLink])

    const handleImageChange = async (indexToUpdate, newImage) => {
        const updatedImages = [...images];
        updatedImages[indexToUpdate] = newImage;
        setImages([cidLink]);
        const fileImage = new File([new Blob([updatedImages[indexToUpdate]])], updatedImages[indexToUpdate].name)
        if (fileImage.name.endsWith("jpg") || fileImage.name.endsWith("png") || fileImage.name.endsWith("jpeg")) {
            handleErrorImg(false)
            setPendingImg(true)
            try {
                const response = await client.put([fileImage]);
                const res = await client.get(response)
                const files = await res.files()
                handleCidLink(`${response}.ipfs.w3s.link/${files[0]?.name}`)
            } catch (error) {
                console.log("Error", error)
            }
            setPendingImg(false)
        }
        else {
            handleErrorImg(true)
            handleCidLink("");
            setDefaultImg("");
        }
    };
    const handleDeleteImage = () => {
        handleCidLink("");
        setDefaultImg("");
        handleDelete(true);
    }
    return (
        <Flex width="100%" flexDirection="column" justifyContent="space-between" style={{ gap: "12px" }}>
            <Text bold color="var(--neutral-05, #33383F);" fontSize="18px">Logo</Text>
            <ContainerUpload>
                <ImageUploader
                    onChange={(newImages) => {
                        setImages(newImages);
                    }}
                    acceptType={["jpg", "png", "jpeg"]}
                    value={images}
                    dataURLKey="data_url"
                />
                <Flex
                    width="100%"
                    height="100%"
                    justifyContent="flex-start"
                    style={{ gap: "2rem" }}
                    key={0}
                >
                    {pendingImg ?
                        <Flex width="130px" borderRadius="100%" justifyContent="center" alignItems="center" height="130px" backgroundColor="#E4E8F9">
                            <Spin />
                        </Flex>
                        :
                        cidLink.length > 0 ?
                            <ContainerImg>
                                <CsImg src={renderHttpImage} alt="" />
                                <CsButtonDelete onClick={handleDeleteImage}>
                                    <IconDeleteImage />
                                </CsButtonDelete>
                            </ContainerImg>
                            :
                            defaultImg.length > 0 && defaultImg !== "null" ?
                                <ContainerImg>
                                    <CsImg src={renderHttpImage} alt="" />
                                    <CsButtonDelete onClick={handleDeleteImage}>
                                        <IconDeleteImage />
                                    </CsButtonDelete>
                                </ContainerImg>
                                :
                                <IconUpload fill="#355DFF" />
                    }
                    <Flex style={{ gap: "1rem" }} flexDirection="column" height="100%" justifyContent="center" alignItems="flex-start">
                        <CsButton>
                            <CsInput
                                placeholder="Tải hình ảnh"
                                type="file"
                                accept="image/*"
                                id={`file-input-0`}
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        // const imageUrl = URL.createObjectURL(file);
                                        handleImageChange(0, file);
                                    }
                                }}
                            />
                            <IconUploadImage fill="#2C60EA" />
                            Tải hình ảnh
                        </CsButton>
                        <Text textAlign="center" color="#718096" fontSize={["10px", , , "12px"]}>Hỗ trợ file PNG, JPEG, JPG</Text>
                    </Flex>
                </Flex>
                {errorImg && <Text color="red">Định dạng file không hỗ trợ, vui lòng tải lại</Text>}
            </ContainerUpload>
        </Flex>
    )
}
export default UploadImage;

const CsImg = styled.img`
    width:130px;
    height:130px;
    border-radius: 50%;
    overflow:hidden;
    @media screen and (max-width:768px) {
        width:100px;
        height:100px;
    }
    /* object-fit: fill; */
`
const ContainerUpload = styled(Flex)`
    display:flex;
    width:100%;
    justify-content:center;
    border:3px dashed rgba(53, 93, 255, 0.25);
    border-radius:12px;
    align-items:flex-start;
    flex-direction:column;
    justify-content:space-between;
    padding:1rem;
    gap: 1rem;
    stroke-width: 2px; 
    stroke: rgba(53, 93, 255, 0.25);
    @media screen and (max-width:768px) {
        gap: 0px;
        padding:1rem;
    }
`
const CsButtonDelete = styled.div`
    width: 40px;
    height:40px;
    padding:0;
    bottom: 0%;
    right: 0%;
    position:absolute;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    background-color: #F0F0F0;
    border-radius: 50%;
    cursor:pointer;
    @media screen and (max-width:768px) {
        width: 30px;
        height:30px;
    }
`
const CsInput = styled.input`
    /* width: 40%; */
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    gap:1rem;
    border-radius: 32px;
    padding:12px 18px;
    cursor:pointer;
    border: 1px solid var(--Button, #2C60EA);
    background-color: transparent;
    color:#2C60EA;
    @media screen and (max-width:768px) {
        padding:10px 12px;
        font-size:12px;
    }
`
const CsButton = styled.label`
    /* width: 40%; */
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    gap:1rem;
    border-radius: 32px;
    padding:12px 18px;
    cursor:pointer;
    border: 1px solid var(--Button, #2C60EA);
    background-color: transparent;
    color:#2C60EA;
    @media screen and (max-width:768px) {
        padding:10px 12px;
        font-size:12px;
    }
`
const ContainerImg = styled(Flex)`
    position:relative;
    flex-direction: column;
`